import React, { useEffect } from "react";
import "../styleSheets/home.css";
import groups from "../assets/groups.svg";
import map from "../assets/map.svg";
import architect from "../assets/architecture.svg";
import engineer from "../assets/engineering.svg";
import aboutImg from '../assets/BIM-Architectural.jpg'
import ladder from "../assets/ladder.svg";
import Header from "./Header";
import { motion } from "framer-motion";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import mepService from "../assets/mepService.svg"
import struService from '../assets/struService.svg'
import supService from "../assets/supportService.svg"

export default function Home() {

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);
  const navigate = useNavigate();
  
  const values = [
    "Team Work",
    "Responsibility",
    "Commitment",
    "Reliability",
    "Quality",
    "Customer Focus",
    "Integrity",
    "People Development",
    "Passion",
    "Trust",
    "Simplicity",
  ];
  const repeatedValues = [...values, ...values, ...values, ...values];
  const totalWidth = repeatedValues.length * 40; 
  const virtualContent = [
    {
      title: "Virtual Construction & BIM Services",
      description:
        " Experience the future of construction with our cutting-edge virtual construction and Building Information Modeling (BIM) services. We create digital models for superior visualization, streamlined communication, and enhanced project efficiency.",
      button: "/service",
      img: aboutImg,
    },
    {
      title: "Structural Engineering Services",
      description:
        "Our structural engineering experts prioritize the safety and stability of your project. We provide comprehensive services including analysis, design, and detailed drawings to ensure a strong and lasting structure.",
      button: "/service/structural",
      img: struService,
    },
    {
      title: "MEP Engineering Services",
      description:
        " Our MEP engineers specialize in the design and coordination of mechanical, electrical, and plumbing systems. We create efficient and functional systems that meet all building requirements.",
      button: "/service/mep",
      img: mepService,
    },
    {
      title: "Support Services",
      description:
        " We offer a complete suite of support services to empower your project's success. This includes accurate material estimation, feasibility studies, and value engineering to optimize costs while maintaining quality.",
      button: "/service/support",
      img: supService,
    },
  ];

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  return (
    <div>
      <Header />

      <div className="banner" style={{ marginTop: "5.2rem" }}>
        <div className="content">
          <div className="content_text">
            <h3>Precision Engineering. Built to Exceed   </h3>
            <p>
              We prioritise rigorous planning and execution, creating
              extraordinary outcomes, and bringing your vision to life with the
              greatest level of quality and expertise.
            </p>
          </div>

          <a
            href="mailto:contact@decisiveengg.com"
            className="a_link"
          >
            {" "}
            <button className="banner-btn">get quote now</button>
          </a>
        </div>
      </div>

      <div className="res_banner" style={{ marginTop: "5rem" }}>
        <div className="content">
          <div className="content_text">
            <h3>Precision Engineering. Built to Exceed</h3>
            <p>
              We prioritise rigorous planning and execution, creating
              extraordinary outcomes, and bringing your vision to life with the
              greatest level of quality and expertise.
            </p>
          </div>
          <a
            href="mailto:contact@decisiveengg.com"
            className="a_link"
          >
            {" "}
            <button className="banner-btn">get quote now</button>
          </a>
        </div>
      </div>

      <div className="achivement_container">
        <div className="text">
          <h3>Building the New Realty</h3>
          <p>
            Developing memorable and distinct structures that are sophisticated
            and renowned
          </p>
        </div>
        <div className="achivement_grid">
          <div className="achivements">
            <div className="box">
              <img src={groups} alt="" />
            </div>
            <p className="counts">10,000+</p>
            <p className="about">Satisfied client</p>
          </div>
          <div className="achivements">
            <div className="box">
              <img src={engineer} alt="" />
            </div>
            <p className="counts">4700+</p>
            <p className="about">Skilled workers</p>
          </div>
          <div className="achivements">
            <div className="box">
              <img src={architect} alt="" />
            </div>
            <p className="counts">500+</p>
            <p className="about">project completed</p>
          </div>
          <div className="achivements">
            <div className="box">
              <img src={map} alt="" />
            </div>
            <p className="counts">34</p>
            <p className="about">states</p>
          </div>
        </div>
      </div>
      <div className="core_values_container">
        <h3>Core values</h3>
        <div class="core_values">
          <motion.div
            className="animation_scroll"
            animate={{ x: [0, -totalWidth] }} // Adjust range to fit the container
            transition={{ repeat: Infinity, duration: 10, ease: "linear" }} // Adjust duration as needed
          >
            {repeatedValues.map((value, index) => (
              <div key={index}>{value}</div>
            ))}
          </motion.div>
        </div>
      </div>

      <div className="virtual_construction_contianer">
        <div className="virtual_construction_box">
          <div className="virtual_construction_title">
            <h3>From vision to reality. Your journey begins now.</h3>
            <p>
              Empowering construction projects from vision to completion with a
              comprehensive suite of solutions.
            </p>
          </div>
          <div className="virtual_construction_cards">
            {virtualContent.map((item, index) => {
              const isFirstCard = index === 0;
              const isLastCard = index === virtualContent.length - 1;
              return (
                <div
                  key={index}
                  className={`card custom_card ${
                    isFirstCard ? "first_card" : ""
                  } ${isLastCard ? "con_last_card" : ""}`}
                >
                  <div className="card-img-top"><img className="cardImage" src={item.img} alt="" /></div>
                  <div className="card-body custom_card_body">
                    <h5 className="card-title">{item.title}</h5>
                    <p className="card-text">
                      {truncateText(item.description, 150)}
                    </p>
                    <button
                      onClick={() => navigate(item.button)}
                      className="readmore-btn"
                    >
                      read more
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="build_container">
        <div className="virtual_construction_title build">
          <h3>Let us help you Build</h3>
          <p>
            From dream to reality, let's build it together. We make the process
            seamless.
          </p>
        </div>
        <div className="image">
          <img src={ladder} alt="" />
        </div>
      </div>

      <div className="client_words">
        <div className="client_words_container">
          <h3 style={{ textAlign: "center" }}>Few words from Our Clients</h3>
        </div>

        <div className="client_container">
          <div className="card client_first_card" id="card__contain">
            <div id="client-flex">
            {/* <div className="client_imgCont">
              <img src={client1} alt="" className="client_img"/>
              </div> */}
              <div className="name_designation">

              <h5>Mr.Mohamed Mansour</h5>
              <p>Operations Manager, Vision Development
                Projects, Qatar</p>
              </div>
            </div>
            <div className="card-body client_body">
              <p className="about_client">
                Decisive Engineering's collaboration was key. They delivered
                innovative solutions on time and within budgets.
              </p>
            </div>
          </div>
          <div className="card" id="card__contain">
            <div id="client-flex">
              {/* <div className="client_imgCont">
                <img src={client1} alt="" className="client_img" />
              </div> */}
              <div className="name_designation">
                <h5>Mr.mahmoud Khalid</h5>
                <p>Project Manager, AI Ajjaj Limited Co., W.L.L, Qatar</p>
              </div>
            </div>
            <div className="card-body client_body">
              <p className="about_client">
                Decisive Engineering's expertise helped us overcome complex
                challenges and deliver a successful project. Highly recommend!{" "}
              </p>{" "}
            </div>
          </div>
          <div className="card" id="card__contain">
            <div id="client-flex">
              {/* <div className="client_imgCont">
                <img src={client1} alt="" className="client_img" />
              </div> */}
              <div className="name_designation">
                <h5>Mr.Saajid</h5>
                <p>Managing Director, Pramodha Rebar Pvt Ltd, India</p>
              </div>
            </div>
            <div className="card-body client_body">
              <p className="about_client">
                Decisive Engineering's professionalism impressed us. Their clear
                communication and efficient processes streamlined our
                collaboration.
              </p>
            </div>
          </div>

          <div className="card last_card"  id="card__contain">
          
            <div id="client-flex">
              {/* <div className="client_imgCont">
                <img src={client1} alt="" className="client_img" />
              </div> */}
              <div className="name_designation">
                <h5>Mr.Alagappan</h5>
                <p>
                Procurement manager, AI Hamra Construction & Development Company,
                  Ras AI Khaimah, UAE
                  </p>
              </div>
            </div>
            <div className="card-body client_body">
              <p className="about_client">
                Decisive Engineering's support streamlined procurement for our
                project. Excellent communication and timely deliveries kept us
                on track.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
