import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { LiaAngleDownSolid, LiaAngleUpSolid } from "react-icons/lia";
import "../../styleSheets/service.css";
import DRebar from "../../assets/3D Rebar Detailing .jpg"
import asBuilt from "../../assets/AsBuilt.jpeg"
import rebar from "../../assets/raber.jpeg"
import rebarDetail from "../../assets/Rebar Detailing .jpg"
import shopDraw from "../../assets/Shop Drawings  (1).jpg"
import strucAnalysis from "../../assets/Structural analysis & Design .jfif"
function StruServices() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  const [showTab, setShowTab] = useState(0);

  const showContent = [
    {
      content:"We go beyond the surface, using advanced software and engineering expertise to analyze how your structure will perform under various loads and conditions. This ensures your project meets building codes and withstands the elements, from everyday use to earthquakes.",
            image: strucAnalysis, // Update with valid image path
    },
    {
      content:"Our team of experts translates your design into clear, precise instructions for fabricating and erecting structural steel components. With accurate dimensions and connection details, you can be confident that your steel structures are built to last.",
            image:rebar ,
    },
    {
      content:"The invisible strength behind concrete, rebar plays a critical role. Our rebar detailing services ensure the precise placement and configuration of rebar within concrete elements, maximizing strength and meeting all safety regulations.",
            image:rebarDetail,
    },
    {
      content:"Take your rebar detailing to the next level with 3D modeling. Visualize the placement and interaction of rebars, identifying potential issues early and improving communication throughout the construction process.",
                 image: DRebar,
    },
    {
        content:"From concept to completion, clear communication is key. Our shop drawings provide detailed instructions for fabricating and installing all structural components, ensuring everything fits together seamlessly.",
                     image: shopDraw,
      },
      {
        content:" As your project comes to life, we document any modifications made during construction. These as-built drawings provide an accurate record of the final structure, invaluable for future maintenance and renovations.",
        image:asBuilt,
      },
  ];

  const handleClick = (index) => {
    // Toggle the state: if already showing, hide; if not, show the clicked tab
    setShowTab(showTab === index ? 0 : index);
  };


  return (
    <div>
      <Header />


      <div className="stru_service_banner" style={{ marginTop: "5.2rem" }}>
        {/* <img src={banner} className="banner_img" alt="" /> */}
        {/* <img src={landing} className="res_banner_img" alt="" /> */}

        <div className="content">
          <div className="content_text">
            <h3>
           Structural engineering services

            </h3>
            {/* <p>
            Decisive Engineering offers a full spectrum of Engineering Solutions designed to the global construction industry. Our team collaborates closely with you to understand your needs and goals, translating them into exceptional results that exceed expectations. 

            </p> */}
          </div>

          <a href="mailto:contact@decisiveengg.com">
            {" "}
            <button>get quote now</button>
          </a>
        </div>
      </div>

      <div className="stru_service_res_banner" style={{ marginTop: "5rem" }}>
        <div className="content">
          <div className="content_text">
            <h3>
            Structural engineering services

            </h3>
            {/* <p>
            Decisive Engineering delivers comprehensive engineering solutions globally, working closely with you to translate your vision into exceptional, industry-leading results.

            </p> */}
          </div>

          <a
            href="mailto:contact@decisiveengg.com"
            className="a_link"
          >
            {" "}
            <button>get quote now</button>
          </a>
        </div>
      </div>

      <div className="about_our_service">
        <div className="about_our_service_content">
          <h3>About Our Services</h3>
          <p>
          When it comes to Structural Engineering Services, our focus is on analyzing and designing structural elements within buildings and infrastructure. We prioritize the stability, strength, and safety of the structure, ensuring that it meets all requirements. Our services encompass structural analysis, detailed design of components such as steel and rebar, as well as the preparation of accurate shop drawings and as-built drawings.
   </p>
        </div>
        <div className="about_our_struservice_image">
          {/* <img src={struService} alt="" /> */}
        </div>
      </div>

      <div className="offers_container_box">
        <div className="what_offer">
          <h3>What we offer</h3>
          <p>
         <span style={{fontWeight:"bold"}}>Collaborative engineering, innovative solutions.</span>  We partner with you to transform your vision into reality, delivering high-quality, efficient designs that meet your needs.

          </p>
          <div className="divider"></div>
        </div>

        <div className="offers_container">
          {/* Define the tabs with click handlers to set showTab */}
          <div className="offers">
            <div className="offers_name">
              <h3>Structural Analysis </h3>
              {showTab === 1 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(1)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(1)}
                />
              )}
            </div>
            {showTab === 1 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
              </div>
            )}

            <div className="offers_name">
              <h3> Structural steel detailing</h3>
              {showTab === 2 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(2)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(2)}
                />
              )}
            </div>
            {showTab === 2 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
              </div>
            )}
            <div className="offers_name">
              <h3>Rebar detailing</h3>
              {showTab === 3 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(3)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(3)}
                />
              )}
            </div>
            {showTab === 3 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
              </div>
            )}
            <div className="offers_name">
              <h3>3D - Rebar detailing</h3>
              {showTab === 4 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(4)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(4)}
                />
              )}
            </div>
            {showTab === 4 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
              </div>
            )}
              <div className="offers_name">
              <h3>Shop Drawings</h3>
              {showTab === 5 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(5)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(5)}
                />
              )}
            </div>
            {showTab === 5 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
              </div>
            )}
              <div className="offers_name">
              <h3>AS-Built Drawings</h3>
              {showTab === 6 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(6)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(6)}
                />
              )}
            </div>
            {showTab === 6 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* Display content based on current showTab */}
          {showTab > 0 && (
            <div className="about_offers_name">
              <div className="offers_image">
                
                <img src={showContent[showTab - 1].image} alt="" />
              </div>
            </div>
          )}
        </div>

        {/* mobile and tablet view */}
        <div className="offers_container" id="res_offer">
          <div className="offers">
            <div className="offers_name">
              <h3> Structural analysis</h3>
              {showTab === 1 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(1)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(1)}
                />
              )}
            </div>
            {showTab === 1 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
                <div className="offers_image">
                  <img src={showContent[showTab - 1].image} alt="" />
                </div>
              </div>
            )}

            <div className="offers_name">
              <h3> Structural steel detailing</h3>
              {showTab === 2 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(2)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(2)}
                />
              )}
            </div>

            {showTab === 2 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
                <div className="offers_image">
                  <img src={showContent[showTab - 1].image} alt="" />
                </div>
              </div>
            )}
            <div className="offers_name">
              <h3>Rebar detailing</h3>
              {showTab === 3 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(3)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(3)}
                />
              )}
            </div>
            {showTab === 3 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
                <div className="offers_image">
                  <img src={showContent[showTab - 1].image} alt="" />
                </div>
              </div>
            )}
            <div className="offers_name">
              <h3> 3D - Rebar detailing </h3>
              {showTab === 4 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(4)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(4)}
                />
              )}
            </div>

            {showTab === 4 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
                <div className="offers_image">
                  <img src={showContent[showTab - 1].image} alt="" />
                </div>
              </div>
            )}
               <div className="offers_name">
              <h3> shop drawings </h3>
              {showTab === 5 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(5)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(5)}
                />
              )}
            </div>

            {showTab === 5 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
                <div className="offers_image">
                  <img src={showContent[showTab - 1].image} alt="" />
                </div>
              </div>
            )}
               <div className="offers_name">
              <h3> AS-Built drawings </h3>
              {showTab === 6 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(6)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(6)}
                />
              )}
            </div>

            {showTab === 6 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
                <div className="offers_image">
                  <img src={showContent[showTab - 1].image} alt="" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default StruServices;
