import React, { useState } from 'react';
import whiteLogo from "../assets/DE_logo White.svg";
import poweredBy from "../assets/bluice.svg";
import { Link, useNavigate } from 'react-router-dom';
import qro from '../assets/QRO.jpg';
import Modal from 'react-modal';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

Modal.setAppElement('#root');
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function Footer() {
  const [numPages, setNumPages] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const navigate = useNavigate();

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const showInMapClicked = () => {
    window.open("https://maps.app.goo.gl/1GBY3DFi1EYGAg8YA");
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedDocument(null);
  };

  const openModalWithDocument = (documentType) => {
    setSelectedDocument(documentType);
    setIsOpen(true);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const customStyles = {
    overlay: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: '100%',
      zIndex: '999999',
      overflowY: 'auto',
      height: "100%",
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      position: 'fixed',
      left: '0',
      top: '0',
      right: '0',
      margin: '0 auto',
      maxWidth:"90%",
      width: 'fit-content',
      height: 'fit-content',
      maxHeight: '90%',
      border: 'none',
      overflowY: 'auto'
    },
  };

  const documentFile = selectedDocument === 'terms' 
  ? 'sample-terms-conditions-agreement.pdf'
  : selectedDocument === 'policy' 
  ? 'sample-privacy-policy-template.pdf'
  : null;

  console.log("Selected Document URL:", documentFile); // Add this line for debugging


  return (
    <div>
      <footer className="footer">
        <div className="footer_container">
          <div className="footer_logo">
            <div className="footer_logo_container">
              <a href="https://www.decisiveengg.com/" target="_blank" rel="noopener noreferrer"><img src={whiteLogo} alt="Logo" /></a>
              <img src={qro} alt="QR Code" />
            </div>
            <p>Authentic | High Quality | On time</p>
          </div>
          <div className="contact_detail_container">
            <div className="nav-Container">
              <p className='links'><Link to="/" style={{ textDecoration: "none", color: "#fff" }}>Home</Link></p>
              <p className='links'><Link to="/service" style={{ textDecoration: "none", color: "#fff" }}>Our services</Link></p>
              <p className='links'><Link to="/about" style={{ textDecoration: "none", color: "#fff" }}>About Us</Link></p>
              <p className='links'><Link to="/contact" style={{ textDecoration: "none", color: "#fff" }}>Contact Us</Link></p>
              <a href="mailto:contact@decisiveengg.com">Get quote now</a>
              <p className='links' onClick={handleScrollToTop}>Back to Top</p>
            </div>
            <div className="contact-Container">
              <a href={`tel:${+919629443771}`}>+91 96 294 43771</a>
              <a href="mailto:contact@decisiveengg.com">contact@decisiveengg.com</a>
              <p className="address" onClick={showInMapClicked}>
                SF NO 385 4 POST OFF EAST Tharamangalam, Salem - 636 502, India
              </p>
            </div>
          </div>
          <hr className="footer-hr" />
          <div className="term_details">
            <p><span onClick={() => navigate('/')}>@2024 Decisive Engineering pvt.ltd.</span> Copyrights received.</p>
            <div className="terms">
              <p onClick={() => openModalWithDocument('terms')}>*Terms and Condition</p>
              <p onClick={() => openModalWithDocument('policy')}>*Privacy policy</p>
              <div className="powered_container">
                <a href="https://www.bluice.in/" target="_blank" rel="noopener noreferrer"><img src={poweredBy} alt="Powered By" /></a>
              </div>
            </div>
          </div>
          <div className="res_term_details">
            <div className="responsive_terms">
              <div className="terms">
                <p onClick={() => openModalWithDocument('terms')}>*Terms and Condition</p>
                <p onClick={() => openModalWithDocument('policy')}>*Privacy policy</p>
              </div>
              <p><span onClick={() => navigate('/')}>@2024 Decisive Engineering pvt.ltd.</span> Copyrights received.</p>
            </div>
            <div className="powered_container">
              <a href="https://www.bluice.in/" target="_blank" rel="noopener noreferrer"><img src={poweredBy} alt="Powered By" /></a>
            </div>
          </div>
        </div>
      </footer>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Document Modal"
      >
        {selectedDocument && (
          <div className="responsive-modal">
            <Document file={documentFile} onLoadSuccess={onDocumentLoadSuccess} onLoadError={(err) => console.log('react-pdf error', err)}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} width={Math.min(window.innerWidth * 0.8, 600)} className="pdf-page" />
              ))}
            </Document>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default Footer;
