import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { LiaAngleDownSolid, LiaAngleUpSolid } from "react-icons/lia";
import "../../styleSheets/service.css";
import asbuilt from "../../assets/AS-Built Drawings .jpg";
import buildersWork from "../../assets/Builders work drawing .jpg";
import mepDesign from "../../assets/MEP Design .jpg";
import shopDraw from "../../assets/Shop Drawings  Mep.jpg";

function MepServices() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  const [showTab, setShowTab] = useState(0);

  const showContent = [
    {
      content:
        "Our MEP engineers go beyond basic functionality. We strategically design your HVAC, electrical, plumbing, and fire protection systems to optimize performance, ensure occupant comfort, and meet all building code requirements.",
      image: mepDesign, // Update with valid image path
    },
    {
      content:
        "Our detailed MEP shop drawings provide clear instructions for fabricating and installing all MEP components. This minimizes errors and ensures everything arrives on-site ready for efficient assembly.",
      image: shopDraw,
    },
    {
      content:
        "Collaboration is essential. Builderswork drawings map out exactly how MEP systems will integrate with the building structure. This eliminates potential clashes between MEP components and architectural elements, guaranteeing a perfect fit.",
      image: buildersWork,
    },
    {
      content:
        "Our As-Built Drawings document the final configuration of your MEP systems, providing an invaluable record for future maintenance, troubleshooting, and ensuring the continued smooth operation of your building.",
      image: asbuilt,
    },
  ];

  const handleClick = (index) => {
    // Toggle the state: if already showing, hide; if not, show the clicked tab
    setShowTab(showTab === index ? 0 : index);
  };

  return (
    <div>
      <Header />

      <div className="mep_service_banner" style={{ marginTop: "5.2rem" }}>
        {/* <img src={banner} className="banner_img" alt="" /> */}
        {/* <img src={landing} className="res_banner_img" alt="" /> */}

        <div className="content">
          <div className="content_text">
            <h3>MEP Engineering Services</h3>
            {/* <p>
            Decisive Engineering offers a full spectrum of Engineering Solutions designed to the global construction industry. Our team collaborates closely with you to understand your needs and goals, translating them into exceptional results that exceed expectations. 

            </p> */}
          </div>

          <a href="mailto:contact@decisiveengg.com">
            {" "}
            <button>get quote now</button>
          </a>
        </div>
      </div>

      <div className="mep_service_res_banner" style={{ marginTop: "5rem" }}>
        <div className="content">
          <div className="content_text">
            <h3>MEP Engineering Services</h3>
            {/* <p>
            Decisive Engineering delivers comprehensive engineering solutions globally, working closely with you to translate your vision into exceptional, industry-leading results.

            </p> */}
          </div>

          <a
            href="mailto:contact@decisiveengg.com"
            className="a_link"
          >
            <button>get quote now</button>
          </a>
        </div>
      </div>

      <div className="about_our_service">
        <div className="about_our_service_content">
          <h3>About Our Services</h3>
          <p>
            MEP Engineering Services plays a crucial role in the design and
            coordination of mechanical, electrical, and plumbing systems within
            buildings. Our team specializes in creating efficient HVAC systems,
            electrical distribution networks, plumbing layouts, and fire
            protection systems. We guarantee that these systems function
            effectively and meet the specific requirements of each building.
          </p>
        </div>
        <div className="about_our_mepservice_image">
          {/* <img src={mepService} alt=""/> */}
        </div>
      </div>

      <div className="offers_container_box">
        <div className="what_offer">
          <h3>What we offer</h3>
          <p>
            Decisive Engineering empowers your vision. We offer a comprehensive
            suite of services designed to transform your project from concept to
            completion, ensuring efficiency, budget control, and the highest
            quality standards.
          </p>
          <div className="divider"></div>
        </div>

        <div className="offers_container">
          {/* Define the tabs with click handlers to set showTab */}
          <div className="offers">
            <div className="offers_name">
              <h3>MEP design</h3>
              {showTab === 1 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(1)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(1)}
                />
              )}
            </div>
            {showTab === 1 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
              </div>
            )}

            <div className="offers_name">
              <h3> Shop drawings </h3>
              {showTab === 2 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(2)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(2)}
                />
              )}
            </div>
            {showTab === 2 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
              </div>
            )}
            <div className="offers_name">
              <h3>builderswork drawings</h3>
              {showTab === 3 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(3)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(3)}
                />
              )}
            </div>
            {showTab === 3 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
              </div>
            )}
            <div className="offers_name">
              <h3>AS-Built drawings</h3>
              {showTab === 4 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(4)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(4)}
                />
              )}
            </div>
            {showTab === 4 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* Display content based on current showTab */}
          {showTab > 0 && (
            <div className="about_offers_name">
              <div className="offers_image">
                <img src={showContent[showTab - 1].image} alt="" />
              </div>
            </div>
          )}
        </div>

        {/* mobile and tablet view */}
        <div className="offers_container" id="res_offer">
          <div className="offers">
            <div className="offers_name">
              <h3> MEP design</h3>
              {showTab === 1 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(1)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(1)}
                />
              )}
            </div>
            {showTab === 1 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
                <div className="offers_image">
                  <img src={showContent[showTab - 1].image} alt="" />
                </div>
              </div>
            )}

            <div className="offers_name">
              <h3> Shop drawings </h3>
              {showTab === 2 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(2)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(2)}
                />
              )}
            </div>

            {showTab === 2 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
                <div className="offers_image">
                  <img src={showContent[showTab - 1].image} alt="" />
                </div>
              </div>
            )}
            <div className="offers_name">
              <h3>builderswork drawings</h3>
              {showTab === 3 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(3)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(3)}
                />
              )}
            </div>
            {showTab === 3 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
                <div className="offers_image">
                  <img src={showContent[showTab - 1].image} alt="" />
                </div>
              </div>
            )}
            <div className="offers_name">
              <h3> AS-Built drawings </h3>
              {showTab === 4 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(4)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(4)}
                />
              )}
            </div>

            {showTab === 4 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
                <div className="offers_image">
                  <img src={showContent[showTab - 1].image} alt="" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default MepServices;
