import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { LiaAngleDownSolid, LiaAngleUpSolid } from "react-icons/lia";
import "../../styleSheets/service.css";
import quantityOff from'../../assets/Quantity Takeoff.png'
import modalBase from '../../assets/Model Based Quantity Takeoff.png'
import feasibilty from '../../assets/feasibility-study (1).jpg'
import valueCost from '../../assets/ValueCost.png'
function SupportServices() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);
  const [showTab, setShowTab] = useState(0);

  const showContent = [
    {
      content:" Get a clear picture of your material needs. Our meticulous quantity take-off process helps you accurately estimate the amount of materials required for construction, leading to better budgeting, procurement planning, and resource management.",
             image: quantityOff, // Update with valid image path
    },
    {
      content:" Leverage technology for even greater accuracy. We utilize BIM software to extract precise material quantities directly from your project's 3D model, streamlining the estimation process and improving cost control.",
            image: modalBase,
    },
    {
      content:" Make informed decisions before you break ground. Our comprehensive feasibility studies assess the technical, operational, financial, and legal aspects of your project, identifying potential risks and opportunities to ensure your project is viable and sets you up for success.",
            image:feasibilty,
    },
    {
      content:"Get the most out of your project budget. Our value engineering approach analyzes your design, materials, and construction methods to identify areas for improvement. We focus on maximizing value while reducing costs, and promoting innovation, sustainability, and overall project efficiency.",
                   image:valueCost,
    },
  ];

  const handleClick = (index) => {
    // Toggle the state: if already showing, hide; if not, show the clicked tab
    setShowTab(showTab === index ? 0 : index);
  };


  return (
    <div>
      <Header />


      <div className="sup_service_banner "  style={{ marginTop: "5.2rem" }}>
        {/* <img src={banner} className="banner_img" alt="" /> */}
        {/* <img src={landing} className="res_banner_img" alt="" /> */}

        <div className="content">
          <div className="content_text">
            <h3>
       Support services

            </h3>
            {/* <p>
            Decisive Engineering offers a full spectrum of Engineering Solutions designed to the global construction industry. Our team collaborates closely with you to understand your needs and goals, translating them into exceptional results that exceed expectations. 

            </p> */}
          </div>

          <a href="mailto:contact@decisiveengg.com">
            {" "}
            <button>get quote now</button>
          </a>
        </div>
      </div>

      <div className="sup_service_res_banner" style={{ marginTop: "5rem" }}>
        <div className="content">
          <div className="content_text">
            <h3>
            Support services

            </h3>
            {/* <p>
            Decisive Engineering delivers comprehensive engineering solutions globally, working closely with you to translate your vision into exceptional, industry-leading results.

            </p> */}
          </div>

          <a
            href="mailto:contact@decisiveengg.com"
            className="a_link"
          >
            {" "}
            <button>get quote now</button>
          </a>
        </div>
      </div>

      <div className="about_our_service">
        <div className="about_our_service_content">
          <h3>About Our Services</h3>
          <p>
          We also provide comprehensive Support services to ensure the success of your project. Our offerings include quantity take-off services for accurate material estimation, model-based quantity take-off for precise quantity calculations, feasibility studies to assess project viability, and value engineering to optimize costs without compromising quality or performance. With our support and expertise, we are committed to guiding you through every step of the project lifecycle.
   </p>
        </div>
        <div className="about_our_supservice_image">
          {/* <img src={supService} alt="" /> */}
        </div>
      </div>

      <div className="offers_container_box">
        <div className="what_offer">
          <h3>What we offer</h3>
          <p>
          We engineer success. We leverage cutting-edge tech and passionate experts to deliver a full spectrum of services, from concept to build. Expect exceptional results for structures that endure.
   </p>
          <div className="divider"></div>
        </div>

        <div className="offers_container">
          {/* Define the tabs with click handlers to set showTab */}
          <div className="offers">
            <div className="offers_name">
              <h3>Quantity take-Off </h3>
              {showTab === 1 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(1)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(1)}
                />
              )}
            </div>
            {showTab === 1 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
              </div>
            )}

            <div className="offers_name">
              <h3> model-based quantity take-off</h3>
              {showTab === 2 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(2)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(2)}
                />
              )}
            </div>
            {showTab === 2 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
              </div>
            )}
            <div className="offers_name">
              <h3>feasibility study</h3>
              {showTab === 3 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(3)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(3)}
                />
              )}
            </div>
            {showTab === 3 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
              </div>
            )}
            <div className="offers_name">
              <h3>value engineering</h3>
              {showTab === 4 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(4)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(4)}
                />
              )}
            </div>
            {showTab === 4 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* Display content based on current showTab */}
          {showTab > 0 && (
            <div className="about_offers_name">
              <div className="offers_image">
                
                <img src={showContent[showTab - 1].image} alt="" />
              </div>
            </div>
          )}
        </div>

        {/* mobile and tablet view */}
        <div className="offers_container" id="res_offer">
          <div className="offers">
            <div className="offers_name">
              <h3> Quantity take-Off</h3>
              {showTab === 1 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(1)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(1)}
                />
              )}
            </div>
            {showTab === 1 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
                <div className="offers_image">
                  <img src={showContent[showTab - 1].image} alt="" />
                </div>
              </div>
            )}

            <div className="offers_name">
              <h3>model-based quantity take-off </h3>
              {showTab === 2 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(2)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(2)}
                />
              )}
            </div>

            {showTab === 2 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
                <div className="offers_image">
                  <img src={showContent[showTab - 1].image} alt="" />
                </div>
              </div>
            )}
            <div className="offers_name">
              <h3>feasibility study</h3>
              {showTab === 3 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(3)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(3)}
                />
              )}
            </div>
            {showTab === 3 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
                <div className="offers_image">
                  <img src={showContent[showTab - 1].image} alt="" />
                </div>
              </div>
            )}
            <div className="offers_name">
              <h3> value engineering</h3>
              {showTab === 4 ? (
                <LiaAngleUpSolid
                  className="icon"
                  onClick={() => handleClick(4)}
                />
              ) : (
                <LiaAngleDownSolid
                  className="icon"
                  onClick={() => handleClick(4)}
                />
              )}
            </div>

            {showTab === 4 && (
              <div className="about_offers_name">
                <div className="about_Offers">
                  <p className="about_offer">
                    {showContent[showTab - 1].content}
                  </p>
                </div>
                <div className="offers_image">
                  <img src={showContent[showTab - 1].image} alt="" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default SupportServices;
